<template>
  <div class="main-container">
    <Tabs
      :tabOptions="tabOptions"
      :onTabUpdated="(index) => switchTab(index)"
    />

    <div class="main-heading">
      <h2>{{ "Coop" }}</h2>
      <Button
        :onClick="() => submit()"
        type="info"
        text="Submit"
        :disabled="!isDirty"
        :loading="isLoading"
      />
    </div>

    <div v-if="configObj" class="subcontainer">
      <coop-cap
        v-if="selectedTabIndex === 0"
        :data="configObj"
        :onChanged="(data) => onDataChanged(data)"
      />
      <coop-ban
        v-if="selectedTabIndex === 1"
        :data="configObj"
        :onChanged="(data) => onDataChanged(data)"
      />
    </div>

    <new-confirm-modal
      :mode="modalMode"
      :isOpen="modalState"
      :changes="changes"
      :onReject="() => closeModal()"
      :onApprove="() => confirmSubmit()"
    />
  </div>
</template>

<script>
import { onBeforeMount, onBeforeUnmount, ref, watch } from "vue";
import Tabs from "../../components/common/Tabs.vue";
import CoopBan from "./componens/CoopBan.vue";
import CoopCap from "./componens/CoopCap.vue";
import { useStore } from "vuex";
import dispatchMap from "@/constants/dispatchMap";
import { useChangeTracker } from "../../composables/changeTracker";
import { useModal } from "../../composables/modal";
import NewConfirmModal from "../../components/common/NewConfirmModal.vue";
import Button from "../../components/common/Button.vue";

export default {
  name: "Coop",
  components: {
    Tabs,
    CoopBan,
    CoopCap,
    NewConfirmModal,
    Button,
  },
  setup() {
    const store = useStore();
    const usedPages = ["coop"];
    const selectedTabIndex = ref(0);
    const configObj = ref(null);

    const {
      isSync,
      isDirty,
      updateTrackerState,
      changes,
      checkChanges,
      onChangesSubmitted,
    } = useChangeTracker("Coop", "", true);
    const { modalState, modalMode, openModal, closeModal } = useModal();
    const isLoading = ref(false);

    onBeforeMount(() => {
      usedPages.forEach((page) => {
        dispatchMap[page].forEach((dispatchStr) => {
          store.dispatch(dispatchStr);
        });
      });
    });

    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    watch(
      () => store.getters["serverConfig/getCoopAfkDetectorConfig"],
      (value) => {
        if (!value) {
          return;
        }

        configObj.value = JSON.parse(JSON.stringify(value));
        updateTrackerState(configObj.value);
        store.dispatch("loader/loadingStatus", false)
      }
    );

    watch(isSync, (value) => {
      if (!value) {
        openModal(1);
      }
    });

    const switchTab = (index) => {
      selectedTabIndex.value = index;
    };

    const onDataChanged = (data) => {
      configObj.value = data;
      updateTrackerState(data);
    };

    const submit = () => {
      checkChanges();
      openModal(0);
    };

    const confirmSubmit = () => {
      closeModal();
      if (modalMode.value === 1) {
        window.location.reload();
        return;
      }
      isLoading.value = true;
      store
        .dispatch("serverConfig/updateCoopAfkDetectorConfig", configObj.value)
        .then(() => {
          onChangesSubmitted();
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    return {
      tabOptions: ["Cap System", "Ban System"],
      selectedTabIndex,
      switchTab,
      configObj,
      submit,
      confirmSubmit,
      isDirty,
      changes,
      modalState,
      onDataChanged,
      closeModal,
      isLoading,
      modalMode
    };
  },
};
</script>

<style scoped>
.subcontainer {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  padding: 0px 30px;
}

.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-heading {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
</style>