<template>
  <div class="menu" :style="'grid-template-columns: ' + templateColumns">
    <div
      :class="selectedTabIndex === index ? 'menu-item-selected' : 'menu-item'"
      v-for="(element, index) in tabOptions"
      v-bind:key="index"
      @click="() => switchTab(index)"
    >
      <p>{{ element }}</p>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
  props: {
    tabOptions: {
      type: Array,
      required: true,
    },
    initialTab: {
        type: Number,
        required: false,
        default: 0
    },
    onTabUpdated: {
        type: Function,
        required: true
    }
  },

  setup(props) {

    const selectedTabIndex = ref(props.initialTab);

    const switchTab = (index) => {
        selectedTabIndex.value = index;
        props.onTabUpdated(index);
    }

    return {
        switchTab,
        selectedTabIndex,
        templateColumns: props.tabOptions.reduce((prev) => prev + '1fr ', '')
    }
  },
};
</script>

<style scoped>
.menu {
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
}

.menu-item {
  cursor: pointer;
  border-bottom: 1px solid grey;
  font-size: 15px;
  padding: 10px 20px;
  min-width: 200px;
}

.menu-item:hover {
  background-color: lavender;
}

.menu-item-selected {
  border-bottom: 1px solid grey;
  font-size: 15px;
  background-color: khaki;
  min-width: 200px;
  padding: 10px 20px;
}
</style>