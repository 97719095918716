<template>
  <div class="first-section">
    <div class="col">
      <div class="form-field">
        <h4>Is Ban System Enabled</h4>
        <input class="input" v-model="state.is_enabled" type="checkbox" />
      </div>
      <div class="form-field">
        <h4>Is Ban System Enabled on Bot Matches</h4>
        <input
          class="input"
          v-model="state.is_enabled_on_bot"
          type="checkbox"
        />
      </div>
      <div class="form-field">
        <h4>No Summon Warning Threshold</h4>
        <input
          class="input"
          v-model="state.no_summon_penalty_time"
          type="number"
        />
      </div>
      <div class="form-field">
        <h4>No Action Warning Threshold</h4>
        <input
          class="input"
          v-model="state.no_action_threshold"
          type="number"
        />
      </div>
    </div>
    <div class="col">
      <div class="ban-table-row header-row">
        <p>Type</p>
        <p>Duration</p>
        <p></p>
        <div class="cell">
          <Button
            :onClick="() => addBanTier()"
            text="Add"
            type="success"
            size="sm"
          />
        </div>
      </div>
      <div
        v-for="(item, index) of state.penalties"
        v-bind:key="index"
        class="ban-table-row header-row"
      >
        <select v-model.number="item.type" @change="initializeRewardType(item)">
          <option
            v-for="option in banTypes"
            :value="option.value"
            v-bind:key="option.value"
          >
            {{ option.text }}
          </option>
        </select>
        <input v-if="item.type === 1" type="number" v-model="item.time" />
        <div v-else></div>
        <select v-if="item.type === 1" v-model.number="item.time_type">
          <option
            v-for="option in banDurationTypes"
            :value="option.value"
            v-bind:key="option.value"
          >
            {{ option.text }}
          </option>
        </select>
        <div v-else></div>
        <div class="cell">
          <Button
            :onClick="() => removeBanTier(index)"
            text="Remove"
            type="error"
            size="sm"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, watch } from "vue";
import Button from "../../../components/common/Button.vue";
export default {
  components: {
    Button,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    onChanged: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const state = reactive(JSON.parse(JSON.stringify(props.data)));

    watch(
      state,
      (newData) => {
        if (newData) props.onChanged(newData);
      },
      { immediate: true }
    );

    const initializeRewardType = (item) => {
      if (item.type === 0) {
        delete item.time_type;
        delete item.time;
      } else if (item.type === 1) {
        (item.time_type = 0), (item.time = 30);
      }
    };

    const addBanTier = () => {
      state.penalties.push({ type: 0 });
    };

    const removeBanTier = (idx) => {
      state.penalties.splice(idx, 1);
    };

    return {
      state,
      addBanTier,
      removeBanTier,
      initializeRewardType,
      banTypes: [
        { text: "Warning", value: 0 },
        { text: "Timed Ban", value: 1 },
      ],
      banDurationTypes: [
        { text: "Minutes", value: 0 },
        { text: "Hours", value: 1 },
        { text: "Days", value: 2 },
      ],
    };
  },
};
</script>

<style scoped>
.input {
  width: 300px;
  height: 35px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-right: 10px;
}

.first-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: start;
}

.ban-table-row {
  padding: 10px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.header-row {
  border-bottom: 1px solid gray;
}

.cell {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>