<template>
  <div class="first-section">
    <div class="col">
      <div class="form-field">
        <h4>Match Count</h4>
        <input class="input" v-model="state.cap_config.match_count" type="number" />
      </div>
      <div class="form-field">
        <h4>Match with Ads Count</h4>
        <input class="input" v-model="state.cap_config.match_with_ads_count" type="number" />
      </div>
      <div class="form-field">
        <h4>Refresh Interval (Hours)</h4>
        <input class="input" v-model="state.cap_config.refresh_interval_hours" type="number" />
      </div>
    </div>
    <div class="col">
    </div>
  </div>
</template>

<script>
import { reactive, watch } from "vue";
export default {
  components: {},
  props: {
    data: {
      type: Object,
      required: true,
    },
    onChanged: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const state = reactive(JSON.parse(JSON.stringify(props.data)));

    watch(
      state,
      (newData) => {
        if (newData) props.onChanged(newData);
      },
      { immediate: true }
    );
    return {
      state,
    };
  },
};
</script>

<style scoped>
.input {
  width: 300px;
  height: 35px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-right: 10px;
}

.first-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: start;
}
</style>